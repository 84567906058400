<template>
    <div class="containerst">
        <div class="container">
            <div class="title title1">
                <img class="title_img" src="../assets/images/logo1.png" alt="">
            </div>
            <div class="title_conts" style="z-index: 9;position: relative;">
                <img src="../assets/images/contact.png" alt="">
            </div>
            <div class="title">
                <img class="logos" src="../assets/images/logo.png" alt="">
            </div>
        </div>

        <ul class="icon_list">
            <li v-for="(item, index) in ico_list" :key="index">
                <a :href="item.href" :style="item.style"></a>
            </li>
        </ul>
        <div class="cont_bgr">
            <p>$GrokKing coin :Grok is  King👑 - A new better answer "Grok"</p>
            <p>This token is simply paying homage to a meme we all love and recognize.</p>
            <br>
            <p>$GrokKing is a meme coin with no intrinsic value or expectation of financial</p>
            <p>return. There is no formal team or roadmap. the coin is completely useless</p>
        </div>

        <footer>
            <p class="bottom">
                2023 @GrokKing👑 Finance, an anon community, brand, and movement
            </p>

        </footer>

        <!-- <div class="pic">
            <img src="../assets/images/cont01.png" alt="">
        </div>
        <div class="pic pic2">
            <img src="../assets/images/cont02.png" alt="">
        </div> -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            ico_list: [{
                href: 'https://twitter.com/GrokKingbsc'
            }, {
                href: 'https://t.me/GrokKingGlobal'
            }, {
                href: '#'
            }]
        }
    },
    created() {
        this.iconlist()
    },
    methods: {
        iconlist() {
            const icoarr = []
            this.ico_list.forEach((item, index) => {
                icoarr.push({
                    style: `background-image: url(../img/bottom_ico_${index + 1}.png)`,
                    href: item.href
                })
            });
            this.ico_list = icoarr
        }
    },
}
</script>

<style lang="less" scoped>
.cont_bgr {
    width: 100%;
    padding: 50px;

    p {
        text-align: center;
        color: #fff;
    }
}

.title_conts {
    margin-top: 50px;
}

footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    // background-color: rgba(233, 62, 125, .5);
    border-top: 1px solid #957934;
    border-radius: 10px 10px 0 0;
    font-size: 14px;
    padding: 30px 20px;
    text-align: center;

    p {
        opacity: .59;
        color: #fff;
        font-family: 'Inter';
    }
}

.containerst {
    position: relative;
    background-image: url('../assets/images/CONTACTBG.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: left 240px;

    .pic {
        position: absolute;
        top: 10%;
        left: 20%;
        width: 120px;
        img {
            width: 100%;
        }
    }
    .pic2 {
        left: auto;
        right: 0;
        top: 0;
        width: 200px;
    }

    .imgs {
        display: flex;
        justify-content: end;
        position: absolute;
        right: 0;
        top: 20%;

        img {
            width: 80%;
        }
    }

    .imgs1 {
        position: absolute;
        left: 0;
        bottom: 10%;
    }
}

.text_main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;

    p {
        width: 80%;
        color: #c1c1c1;
        font-size: 22px;
        text-align: center;
        font-family: 'jose_ligh' !important;
    }
}

.container {
    width: 100%;
    padding-top: 50px;
    // background: linear-gradient(to bottom, #0a0d22, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));

    .title1 {
        position: relative;

        img {
            width: 109px;
        }
    }

    .title {
        text-align: center;

    }

}

.icon_list {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    flex-wrap: wrap;
    margin-bottom: 50px;

    li {
        margin: 0 20px;

        a {
            display: block;
            width: 68px;
            height: 60px;
            background: url('../assets/images/icon3.png') no-repeat;
            background-size: 100% auto;
        }
    }
}

@media screen and (max-width:700px) {
    .text_main p {
        text-align: left !important;
        margin-bottom: 5px;
    }

    .icon_list {

        li {
            margin: 10px;
        }
    }

    .logos {
        width: 40%;
    }
}

@media screen and (max-width:500px) {

    .title_conts {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .title_conts img {
        width: 70%;
    }
}
</style>