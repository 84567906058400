<template>
    <header>
        <div class="main1">
            <div class="logo">
                <img src="../assets/images/logo.png" alt="">
            </div>
            <nav>
                <ul class="nav" v-show="show">
                    <li>
                        <a href="#about">ABOUT</a>
                    </li>
                    <li>
                        <a href="#HOW">HOW TO BUY</a>
                    </li>
                    <li>
                        <a href="#TOKENOMICS">TOKENOMICS</a>
                    </li>
                    <li>
                        <a href="#ROADMAP">ROADMAP</a>
                    </li>
                    <li>
                        <a class="nav_btn"
                            href="https://pancakeswap.finance/swap?outputCurrency=0x010b0293d810cdE17A4D31D027b1C1797fabeED0">
                            <img src="../assets/images/buynow.png" alt="">
                        </a>
                    </li>
                </ul>
                <div class="menu" @click="menu()">
                    <img src="../assets/images/menu.png" alt="">
                </div>
            </nav>
        </div>
        <!-- <div class="line"></div> -->
    </header>
    <div class="h"></div>
</template>

<script>
export default {
    data() {
        return {
            show: false,
        }
    },
    mounted() {
        this.bodyW = document.body.clientWidth
        if (this.bodyW <= 1120) {
            this.show = false
        } else {
            this.show = true
        }
        window.onresize = () => {
            this.bodyW = document.body.clientWidth
            if (this.bodyW <= 1120) {
                this.show = false
            } else {
                this.show = true
            }
        }
    },
    methods: {
        menu() {
            this.show = !this.show
        },
    },
}
</script>

<style lang="less" scoped>
.h {
    height: 99px
}

.line {
    height: 1px;
    width: 100%;
    background: #f2f3f5;
}


header {
    position: fixed;
    width: 100vw;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .15);
    backdrop-filter: blur(10px);
    border-bottom: 1px solid #be3069;
    z-index: 9999;
    padding: 10px 0;


    .logo {
        font-family: 'Another';
        width: 170px;
        padding: 10px 0;

        img {
            width: 100%;
        }
    }

    .main1 {
        display: flex;
        justify-content: space-around;
        align-items: center;
        color: #fff;
    }

    nav {
        display: flex;

        .nav {
            display: flex;

            li {
                margin: 0 20px;

                a {
                    display: flex;
                    align-items: center;
                    height: 100%;
                    color: #fff;
                    font-family: 'roboto';
                    font-weight: bold;
                    font-size: 17px;
                }
            }

            a {
                display: flex;
                align-items: center;

                &.nav_btn {
                    padding: 8px 28px;
                    width: 200px;
                    /* Buy Now */
                    font-family: 'Unbounded';
                    font-weight: 700;
                    font-size: 23px;
                    line-height: 29px;
                    /* identical to box height */
                    display: flex;
                    align-items: center;
                    text-align: center;
                    letter-spacing: 0.5px;
                    border-radius: 6px;
                    color: #000000;

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.menu {
    display: none;
    width: 40px;
    padding: 10px 0;
    margin-right: 15px;

    img {
        width: 100%;
    }
}

@media screen and (max-width:1120px) {
    header {
        nav {
            .nav {
                position: absolute;
                display: flex;
                flex-direction: column;
                align-items: center;
                top: 0;
                left: 0;
                width: 100%;
                background-color: rgba(0, 0, 0, .5);
                padding: 80px 0 50px 0;
                backdrop-filter: blur(3px);

                li {
                    margin: 10px 0;

                    a {
                        font-size: 18px;
                    }
                }

                // display: none;
            }

            .menu {
                display: block;
                position: relative;
                z-index: 9;
            }
        }
    }
}
</style>
